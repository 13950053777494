import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/MainPages/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MainPages/LandingPage.vue"
      ),
    children: [
      {
        path: "/Admins",
        name: "Admins",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Admins.vue"
          ),
      },
      {
        path: "/Organizations",
        name: "Organizations",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Organizations.vue"
          ),
      },
      {
        path: "/Questions",
        name: "Questions",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Questions.vue"
          ),
      },
      {
        path: "/QuestionsReview",
        name: "QuestionsReview",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/QuestionsReview.vue"
          ),
      },
      {
        path: "/Categories",
        name: "Categories",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Categories.vue"
          ),
      },
      {
        path: "/Quiz",
        name: "Quiz",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Quiz.vue"
          ),
      },
      {
        path: "/QuizRuns",
        name: "QuizRuns",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/QuizRuns.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

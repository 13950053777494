<template>
  <v-app style="background-color: #01224e">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.FontSize12px {
  font-size: 12px !important;
}
.FontSize14px {
  font-size: 14px !important;
}
.FontSize16px {
  font-size: 16px !important;
}
.FontSize18px {
  font-size: 18px !important;
}
.FontSize20px {
  font-size: 20px !important;
}
.FontSize22px {
  font-size: 22px !important;
}
.FontSize24px {
  font-size: 24px !important;
}
.FontSize26px {
  font-size: 26px !important;
}
.FontSize28px {
  font-size: 28px !important;
}
.FontSize30px {
  font-size: 30px !important;
}
.FontFamilyVariant1 {
  font-family: "Geist", "Nunito Sans" !important;
}
.FontFamilyVariant2 {
  font-family: Gabriola, Times, serif !important;
}
.FontFamilyVariant3 {
  font-family: "Times New Roman", Times, serif !important;
}
.WidthVariant1 {
  width: 400px !important;
}
.WidthVariant2 {
  max-width: 200px !important;
}
.dialogStickyClass {
  align-self: flex-start !important;
}
.textField.v-text-field .v-field__input .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  padding-bottom: 0 !important;
}
.dropdownField .v-input__control {
  height: 35px !important;
}
.dropdownFieldIcon .v-field__append-inner {
  padding-bottom: 10px !important;
}
.tableClassVariant th {
  height: 32px !important;
  font-size: 13px !important;
  background: linear-gradient(to bottom, #d8d8d8, #e5e9ee) !important;
  color: #000 !important;
}
.tableClassVariant td {
  background-color: #ffffff00 !important;
  height: 32px !important;
  font-size: 12px !important;
}

.tableClassVariant .v-data-table__tr {
  background: rgba(0, 0, 0, 0) !important;
}
.textFieldbg.v-text-field .v-field {
  background: #fac62a !important;
  color: #000 !important;
}
.v-field__input {
  font-size: 12px !important;
}
.textArea .v-field__input {
  font-size: 20px !important;
  text-align: center !important;
  justify-content: center !important;
  justify-items: center !important;
  height: 200px !important;
  margin-top: 10% !important;
  padding: 40px !important;
  overflow: hidden !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
}
.paddingVariant1 {
  padding: 130px !important;
}
.paddingVariant2 {
  padding: 65px !important;
}
.CursorVariant {
  cursor: pointer !important;
}
.ansBackground {
  background-color: #fde8a9 !important;
  width: 200px !important;
  margin-left: 12px !important;
  border-radius: 20px !important;
}
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}
/* For Webkit-based browsers (Chrome, Safari, etc.) */
.hide-scrollbar ::-webkit-scrollbar {
  display: none;
}
.loader {
  width: 50px;
  margin-top: 700% !important;
  margin-left: 1700% !important;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #24156d #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}
.QuestionText {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 200px !important;
}
.QuestionOptions {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100px !important;
}
.hintMessage.v-text-field .v-input__details{
  padding-inline: 8px !important;
}
</style>
